import {lazy, Suspense, useEffect, useState} from 'react'

/**
 * The production build of DevTools is a lazy import only. The regular export
 * is null in production.
 *
 * Using this import style lets us access the panel in production and review lab
 */
const ReactQueryDevtoolsPanel = lazy(async () => {
  const mod = await import('@tanstack/react-query-devtools/build/modern/production.js')
  return {default: mod.ReactQueryDevtoolsPanel}
})

/** Matches the id defined in app/views/stafftools/staffbar/_stats_full.html.erb */
const BUTTON_ID = '#react-query-devtools-toggle-button'

export function StaffBarReactQueryDevToolsPanel() {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  /**
   * Listen for clicks on the staffbar toggle
   */
  useEffect(() => {
    function handleToggle(e: Event) {
      if (e.target instanceof Element && e.target.closest(BUTTON_ID)) {
        setIsOpen(s => !s)
      }
    }
    document.addEventListener('click', handleToggle)
    return () => {
      document.removeEventListener('click', handleToggle)
    }
  }, [])

  /**
   * Sync staffbar toggle tooltip and aria-label based on state
   */
  useEffect(() => {
    const button = document.querySelector<HTMLButtonElement>(BUTTON_ID)
    if (!button) return
    const text = isOpen ? 'Close react query devtools panel' : 'Open react query devtools panel'
    button.ariaLabel = text

    const tooltipId = button.getAttribute('aria-describedby')
    if (tooltipId) {
      const toolltipEl = document.querySelector<HTMLElement>(`#${tooltipId}`)
      if (toolltipEl) {
        toolltipEl.textContent = text
      }
    }
  }, [isOpen])

  if (!isOpen) return
  return (
    <Suspense fallback={null}>
      <ReactQueryDevtoolsPanel
        style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          height: '500px',
          left: 0,
          zIndex: 10000,
        }}
        onClose={() => {
          setIsOpen(false)
        }}
      />
    </Suspense>
  )
}

try{ ReactQueryDevtoolsPanel.displayName ||= 'ReactQueryDevtoolsPanel' } catch {}
try{ StaffBarReactQueryDevToolsPanel.displayName ||= 'StaffBarReactQueryDevToolsPanel' } catch {}